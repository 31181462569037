import Image from "next/image";

import splitImg from "../../public/images/discord/AgeEstimationFlow.gif";
import Link from "next/link";

const Split = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <Image
                      className="radius"
                      src={splitImg}
                      alt="split Images"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
  <div className="split-inner">
    <h4
      className="title"
      data-sal="slide-up"
      data-sal-duration="400"
      data-sal-delay="200"
    >
      Simplicity at Its Core
    </h4>
    <p
      className="description"
      data-sal="slide-up"
      data-sal-duration="400"
      data-sal-delay="300"
    >
      Our mission with Agely is to streamline the age verification process, ensuring it's straightforward and user-friendly. We achieve this by offering:
    </p>
    <ul
      className="split-list"
      data-sal="slide-up"
      data-sal-duration="400"
      data-sal-delay="350"
    >
    <li><strong>Intuitive User Guides:</strong> Detailed instructions accompanied by animated gif tutorials for easy understanding.</li>
    <li><strong>Interactive UI Elements:</strong> Utilization of buttons and modals to minimize reliance on command inputs.</li>
    <li><strong>Straightforward Commands:</strong> When commands are necessary, we ensure they are self-explanatory with clearly defined options.</li>
    </ul>
    <div
      className="view-more-button mt--35"
      data-sal="slide-up"
      data-sal-duration="400"
      data-sal-delay="400"
    >
      <Link className="btn-default" href="/commands">
        Commands
      </Link>
    </div>
  </div>
</div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Split;
