import { useAppContext } from "@/context/Context";
import Link from "next/link";

const HeaderTop = () => {
  const { toggleTop, setToggle } = useAppContext();
  return (
    <>
      <div
        className={`header-top-news bg-image1 ${toggleTop ? "" : "deactive"}`}
      >
        <div className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <div className="content">
                    <span className="rainbow-badge">Try For Free</span>
                    <span className="news-text">
                      Get 10 estimations or ID validations for free!
                    </span>
                  </div>
                  <div className="right-button">
                    <a className="btn-read-more" href="https://discord.com/oauth2/authorize?client_id=1139761108471775263&permissions=2416299078&scope=bot%20applications.commands">
                      <span>
                      <i className="feather-plus-circle"></i> Invite Agely
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="icon-close">
          <button
            className="close-button bgsection-activation"
            onClick={() => setToggle(!toggleTop)}
          >
            <i className="feather-x"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default HeaderTop;
