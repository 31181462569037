import React from "react";

import AccordionData from "../../data/home.json";

const AccordionItem = () => {
  return (
    <>
      <div className="rainbow-accordion-style accordion">
        <div className="accordion" id="accordionExamplea">
          {AccordionData &&
            AccordionData.accordion.map((data, index) => (
              <div className="accordion-item card bg-flashlight" key={index}>
                <h2
                  className="accordion-header card-header"
                  id={`heading${index + 1}`}
                >
                  <button
                    className={`accordion-button ${
                      !data.isExpand ? "collapsed" : ""
                    }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index + 1}`}
                    aria-expanded={data.isExpand ? "true" : "false"}
                    aria-controls={`collapse${index + 1}`}
                  >
                    {data.title}
                  </button>
                </h2>
                <div
                  id={`collapse${index + 1}`}
                  className={`accordion-collapse collapse ${
                    data.isExpand ? "show" : ""
                  }`}
                  aria-labelledby={`heading${index + 1}`}
                  data-bs-parent="#accordionExamplea"
                >
                  <div className="accordion-body card-body">

                    <p><b>{data.desc[0]}</b></p>
                    {data.desc[1] && (
                      <ul>
                        {data.desc[1].map((bullet, bulletIndex) => (
                          <li key={bulletIndex}>{bullet}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default AccordionItem;
